import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { APIS } from "apis";
import { RootState } from "store/rootReducer";
import { Loader } from "components";
import {
  clearCompanySettings,
  storeCompanySettings,
} from "store/settings/actions";
import { useGetData as useGetSettings } from "hooks/useGetData";

export const WithCompanySettings: FC = ({ children }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.auth.auth.id);
  const companyId = useSelector((state: RootState) => state.config.companyId);
  const front = useSelector((state: RootState) => state.config.front);

  const { loading } = useGetSettings(() =>
    APIS.settings.fetchSettings(userId).then((res) => {
      if (res.data) {
        dispatch(storeCompanySettings(res.data));
      } else {
        dispatch(clearCompanySettings());
      }
    })
  );

  if (loading) return <Loader />;

  if (process.env.REACT_APP_ENVIRONMENT === "preproduction") {
    // Set riseup-subdomain to avoid third-party cookies blocking
    return (
      <>
        {children}
        <iframe
          title="Debug"
          src={`${front}/Debug/loginAsCompany/${companyId}`}
          style={{ position: "absolute", width: 0, height: 0, border: 0 }}
        ></iframe>
      </>
    );
  }
  return <>{children}</>;
};
