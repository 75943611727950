import React, { useEffect } from "react";
import { useTeams } from "msteams-react-base-component";
import { HashRouter, Routes, Route } from "react-router-dom";
import PATHS from "core/utils/paths";
import { useTranslation } from "react-i18next";
import { updateUsername } from "store/auth/actions";
import { useDispatch } from "react-redux";
import {
  CompanySelector,
  TermsOfUse,
  WithAuthentication,
  WithCompanySettings,
} from "guard";

const Dashboard = React.lazy(() => import("pages/Dashboard/Dashboard"));
const Catalog = React.lazy(() => import("pages/Catalog/Catalog"));
const Trainings = React.lazy(() => import("pages/Trainings/Trainings"));
const Documents = React.lazy(() => import("pages/Documents/Documents"));
const Groups = React.lazy(() => import("pages/Groups/Groups"));
const NotFound = React.lazy(() => import("pages/NotFound/NotFound"));

const TeamsRoutes = React.memo(() => {
  const [{ context }] = useTeams();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (context) {
      i18n.changeLanguage(context.locale);
      dispatch(updateUsername(context.loginHint ?? ""));
    }
  }, [context]);

  const routes = [
    { path: PATHS.DASHBOARD, element: <Dashboard /> },
    { path: PATHS.CATALOG, element: <Catalog /> },
    { path: PATHS.TRAININGS, element: <Trainings /> },
    { path: PATHS.DOCUMENTS, element: <Documents /> },
    { path: PATHS.GROUPS, element: <Groups /> },
    { path: "*", element: <NotFound /> },
  ];

  return (
    <CompanySelector>
      <WithAuthentication>
        <WithCompanySettings>
          <HashRouter>
            <Routes>
              {React.Children.toArray(
                routes
                  .map((route) => ({
                    ...route,
                    element: <TermsOfUse>{route.element} </TermsOfUse>,
                  }))
                  .map((route) => <Route {...route} />)
              )}
            </Routes>
          </HashRouter>
        </WithCompanySettings>
      </WithAuthentication>
    </CompanySelector>
  );
});

export default TeamsRoutes;
