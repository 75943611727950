import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setLocalStorage } from "core/utils/helpers";
import CONSTANTS from "core/utils/constants";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTeams } from "msteams-react-base-component";

export const useInitialize = () => {
  const [{ inTeams }] = useTeams();
  const { i18n } = useTranslation();
  const [theme, setTheme] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    microsoftTeams.app.initialize().then(() => {
      microsoftTeams.app.getContext().then((context) => {
        setTheme(context.app.theme);
        microsoftTeams.app.registerOnThemeChangeHandler(setTheme);
        microsoftTeams.app.notifyAppLoaded();
        microsoftTeams.app.notifySuccess();
        microsoftTeams.authentication.getAuthToken().then((token) => {
          // Use the access token to make API requests to Microsoft Graph
          setLocalStorage(CONSTANTS.MICROSOFT_GRAPH_TOKEN, token);
        }).catch((reason) => {
          console.log("RUPLOG: GetAuthToken Failure callback", reason);
        });
      });
    });
  }, []);
  return {
    inTeams,
    i18n,
    theme,
    loading,
  };
};
